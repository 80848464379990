<script>
export default {
  name:"reject-page",
  created() {
    let ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      console.log(1)
    } else {
      this.$router.replace('/')
    }

    document.title = 'PUBG'
  }
}



</script>

<template>
  <div style="position: relative">
    <img src="@/assets/reject.jpg"  style="width: 100%" alt="">
    <div class="absolute bottom-0"></div>
  </div>
</template>

<style scoped>
.lr {
  background: linear-gradient(rgba(19, 24, 61, 0), rgba(19, 24, 61, 1));
}

.absolute{
  position: absolute;
}


.bottom-0{
  margin-bottom: 0;
  height: 50px;
  z-index: 10;
  width: 100%;
}

</style>